var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"rounded-xl mx-1 mx-sm-5 mx-md-12 mb-5",attrs:{"no-data-text":"No hay datos disponibles.","no-results-text":"No hay datos disponibles.","loading-text":"Cargando datos...","calculate-widths":true,"headers":_vm.appointmentsHeaders,"items":_vm.appointmentsData.data,"hide-default-header":"","disable-sort":"","hide-default-footer":"","items-per-page":_vm.appointmentsSearchData.perPage},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',[(_vm.$vuetify.breakpoint.smAndUp)?_c('tr',{staticClass:"background-tr-color"},[_c('th',{class:`text-${_vm.appointmentsHeaders[0].align}`},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[0].text)+" ")]),_c('th',{class:`text-${_vm.appointmentsHeaders[1].align}`},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[1].text)+" ")]),_c('th',{class:`text-${_vm.appointmentsHeaders[2].align}`},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[2].text)+" ")]),_c('th',{class:`text-${_vm.appointmentsHeaders[3].align}`},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[3].text)+" ")]),_c('th',{class:`text-${_vm.appointmentsHeaders[4].align}`},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[4].text)+" ")]),_c('th',{class:`text-${_vm.appointmentsHeaders[5].align}`},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[5].text)+" ")]),_c('th',{class:`text-${_vm.appointmentsHeaders[6].align}`},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[6].text)+" ")]),_c('th',{class:`text-${_vm.appointmentsHeaders[7].align}`},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[7].text)+" ")])]):_vm._e(),_vm._l((items),function(item,index){return _c('tr',{key:index,class:[
          _vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-table-row' : '',
        ]},[_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row pt-6' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[0].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.appointmentsHeaders[0].align}`},[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row pt-6' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[1].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.appointmentsHeaders[1].align}`},[_vm._v(" "+_vm._s(item.customer?item.customer.user.name:"")+" "+_vm._s(item.customer?item.customer.user.last_name_father:"")+" "+_vm._s(item.customer?item.customer.user.last_name_mother:"")+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row pt-6' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[2].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.appointmentsHeaders[2].align}`},[_vm._v(" "+_vm._s(item.user?item.user.name:"")+" "+_vm._s(item.user?item.user.last_name_father:"")+" "+_vm._s(item.user?item.user.last_name_mother:"")+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row pt-6' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[3].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.appointmentsHeaders[3].align}`},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formatCreatedDate(item.created_at)))])])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row pt-6' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[4].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.appointmentsHeaders[4].align}`},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formatAppointmentDate(item.start_date)))])])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row pt-6' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[5].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.appointmentsHeaders[5].align}`},[_vm._v(" "+_vm._s(item.status.name)+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row pt-6' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[6].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.appointmentsHeaders[6].align}`},[_vm._v(" "+_vm._s(item.patients?item.patients.length:'NA')+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row pt-6' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.appointmentsHeaders[7].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.appointmentsHeaders[7].align}`},[_vm._v(" "+_vm._s(_vm._f("currency")(item.cost))+" ")])])])})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }